<template>
  <section>
    <section class="navBack">
      <v-container class="container-custom">
        <v-row>
          <v-col md="12" class="topNav">
            <ul class="navList">
              <li>Home</li>
              <li><v-icon> mdi-chevron-right </v-icon> EMI Calculator</li>
            </ul>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section class="my-5 mb-15">
      <v-container class="container-custom">
        <v-row>
          <v-col md="8" cols="12">
            <div class="productImages">
              <img src="@/assets/images/emi.jpeg" alt="" />
            </div>
            <div class="detailBox">
              <h2>Car Loan EMI Calculator</h2>
              <div class="mt-3">
                <p>
                  It is very easy to calculate the EMI for your car loan. You
                  will get EMI as soon as you enter the required loan amount and
                  the interest rate. Installment in EMI calculator is calculated
                  on reducing balance. As per the rules of financing
                  institutions, processing fee or possible charges may be
                  applicable which are not shown in the EMI we calculate.
                </p>
              </div>
            </div>
            <div class="detailBox">
              <h2>Calculate your EMI</h2>
              <div>
                <v-form class="mt-6">
                  <v-row>
                    <v-col md="4" cols="12" class="pb-0">
                      <v-label>Category</v-label>
                      <v-autocomplete
                        outlined
                        placeholder="Select Category"
                        v-model="category"
                        :items="categories"
                        item-text="value"
                        item-value="value"
                        @change="selectCategory"
                        filled
                      ></v-autocomplete>
                    </v-col>
                    <v-col md="4" cols="12" class="pb-0">
                      <v-label>Brand</v-label>
                      <v-autocomplete
                        outlined
                        placeholder="Select Brand"
                        v-model="brand"
                        :items="brands"
                        item-text="value"
                        item-value="value"
                        @change="selectBrand"
                        filled
                      ></v-autocomplete>
                    </v-col>
                    <v-col md="4" class="pb-0">
                      <v-label>Model</v-label>
                      <v-autocomplete
                        outlined
                        placeholder="Select Model"
                        v-model="model"
                        :items="models"
                        item-text="productName"
                        item-value="_id"
                        filled
                           @change="selectProduct"
                      ></v-autocomplete>
                    </v-col>

                    <v-col md="4" cols="12" class="pb-0">
                      <v-label>Vehicle Amount</v-label>
                      <div class="flexBox">
                        <ul>
                          <li class="no-border-right">Rs</li>
                        </ul>
                        <v-text-field
                          class="mt-1"
                          placeholder="Vehicle Loan Amount"
                          outlined
                          v-model="amount"
                          filled
                        ></v-text-field>
                      </div>
                    </v-col>
                    <v-col md="4" cols="12" class="pb-0">
                      <v-label>Downpayment</v-label>
                      <div class="flexBox">
                        <ul>
                          <li class="no-border-right">Rs</li>
                        </ul>
                        <v-text-field
                          class="mt-1"
                          placeholder="Downpayment"
                          outlined
                          v-model="downpayment"
                          @keyup="loadCalculator"
                          filled
                        ></v-text-field>
                      </div>
                    </v-col>

                    <v-col md="4" cols="12" class="pb-0">
                      <v-label>Interest Rate</v-label>
                      <div class="flexBox">
                        <v-text-field
                          class="mt-1"
                          placeholder="Interest Rate"
                          outlined
                          filled
                          v-model="interestRate"
                                 @keyup="loadCalculator"
                        ></v-text-field>
                        <ul>
                          <li class="no-border-left">%</li>
                        </ul>
                      </div>
                    </v-col>
                    <v-col md="4" cols="12" class="pb-0">
                      <v-label>Loan Tenure</v-label>
                      <div class="flexBox">
                        <v-text-field
                          class="mt-1"
                          placeholder="Loan Tenure"
                          outlined
                          v-model="loanTenure"
                                 @keyup="loadCalculator"
                          filled
                        ></v-text-field>
                        <ul>
                          <li
                            class="no-border-left"
                            @click="selectDuration('year')"
                            :class="{ active: loanPer == 'year' }"
                          >
                            Yr
                          </li>
                          <li
                            class="no-border-left"
                            @click="selectDuration('month')"
                            :class="{ active: loanPer == 'month' }"
                          >
                            Mo
                          </li>
                        </ul>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="emiDetail">
                    <ul>
                      <li>Toatl Loan Amount</li>
                      <li>Rs. {{ amount }}</li>
                    </ul>
                    <ul>
                      <li>Downpayment</li>
                      <li>Rs. {{ downpayment }}</li>
                    </ul>
                    <ul class="mb-3">
                      <li>You’ll Pay Extra</li>
                      <li>
                        Rs.
                        {{ numberFormat(emi.total - (amount - downpayment)) }}
                      </li>
                    </ul>
                    <hr />
                    <ul class="mt-3">
                      <li>EMI Per Month</li>
                      <li>Rs. {{ numberFormat(emi.emi) }}</li>
                    </ul>
                    <v-btn class="primaryBtn mt-3" @click="loadCalculator"
                      >Get Quotation</v-btn
                    >
                  </v-row>
                </v-form>
              </div>
            </div>
            <div class="detailBox" v-if="false">
              <h2>Frequently Asked Questions On Emi</h2>
              <div
                class="mt-3 faqPill"
                v-for="(data, index) in faqs"
                :key="index"
              >
                <header>
                  <ul>
                    <li>{{ data.title }}</li>
                    <li>
                      <v-icon v-if="data.status" @click="toggle(index)">
                        mdi-chevron-up </v-icon
                      ><v-icon v-if="!data.status" @click="toggle(index)">
                        mdi-chevron-down
                      </v-icon>
                    </li>
                  </ul>
                </header>
                <p v-if="data.status">
                  {{ data.body }}
                </p>
              </div>
            </div>
          </v-col>
          <v-col md="4">
            <div class="filterationBox">
              <header>
                <h3>Popular Cars</h3>
                <router-link to="/search">
                  View All
                  <v-icon> mdi-chevron-right </v-icon>
                  <v-icon class="secondIcon"> mdi-chevron-right </v-icon>
                </router-link>
              </header>
              <ul class="filterBox" v-if="!loading">
                <li v-for="(data, index) in products" :key="index + 'product'">
                  <ProductList :item="data" />
                </li>
              </ul>

              <Loading v-if="loading" />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </section>
</template>

<script>
// import EMICalculator from "@/components/EMICalculator";
import ProductList from "@/components/ProductList";
import Loading from "@/components/Loading";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "EMICalculatorPage",
  data: () => ({
    amount: 0,
    downpayment: 0,
    interestRate: 10,
    loanTenure: 12,
    loanAmount: 0,
    totalAmount: 0,
    loanPer: "month",
    emi: {},
    faqs: [
      {
        title: "How is car loan EMI calculated monthly?",
        body: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod similique suscipit temporibus repellat possimus iste dignissimos in? Cupiditate, a officia voluptates veritatis, numquam rerum corrupti expedita ducimus quaerat quae excepturi!",
        status: true,
      },
      {
        title: "How is car loan EMI calculated monthly?",
        body: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod similique suscipit temporibus repellat possimus iste dignissimos in? Cupiditate, a officia voluptates veritatis, numquam rerum corrupti expedita ducimus quaerat quae excepturi!",
        status: false,
      },
      {
        title: "How is car loan EMI calculated monthly?",
        body: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod similique suscipit temporibus repellat possimus iste dignissimos in? Cupiditate, a officia voluptates veritatis, numquam rerum corrupti expedita ducimus quaerat quae excepturi!",
        status: false,
      },
    ],
    products: [],
    limit: 5,
    page: 1,
    loading: true,
    category: undefined,
    categories: [],
    filters: [],
    model: undefined,
    models: [],
    brands: [],
    brand: undefined,
  }),
  methods: {
    ...mapActions(["getFilters", "getFrotendProducts","getSingleFrotendProduct"]),
    filterPage() {
      this.$router.push("/search");
    },
    toggle(index) {
      this.faqs.forEach((ele) => {
        ele.status = false;
      });
      this.faqs[index].status = !this.faqs[index].status;
    },
    async selectCategory() {
      await this.getFilters({ category: this.category });
      this.filters = this.allFilters.results;
      this.brands = this.filters.brand.items;
    },
    async selectBrand() {
      await this.getFrotendProducts({
        category: this.category,
        brand: this.brand,
      });
      this.models = this.allProducts.results;
    },
    numberFormat(givenNumber) {
      let internationalNumberFormat = new Intl.NumberFormat("en-US");
      return internationalNumberFormat.format(givenNumber);
    },
    selectDuration(data) {
      this.loanPer = data;
      if (this.loanPer == "year") {
        this.loanTenure = 1;
      } else {
        this.loanTenure = 12;
      }
      this.loadCalculator();
    },
    async selectProduct(){
      await this.getSingleFrotendProduct(this.model);
      this.amount = this.allProduct.results.price;
      this.loadCalculator();
    },
    loadCalculator() {
      if (this.downpayment == "") {
        this.downpayment = 0;
      }
      if (this.amount == "") {
        this.amount = 0;
      }
      if (this.interestRate == "") {
        this.interestRate = 0;
      }
      if (this.loanTenure == "") {
        if (this.loanPer == "year") {
          this.loanTenure = 1;
        } else {
          this.loanTenure = 12;
        }
      }

      this.amount = parseFloat(this.amount);
      this.downpayment = parseFloat(this.downpayment);
      this.interestRate = parseFloat(this.interestRate);
      this.loanTenure = parseFloat(this.loanTenure);
      let loanYear =
        this.loanPer == "year" ? this.loanTenure * 12 : this.loanTenure;
      const interest = this.interestRate / 1200;
      let term = loanYear;
      let top = Math.pow(1 + interest, term);
      let bottom = top - 1;
      let ratio = top / bottom;
      this.loanAmount = (this.amount - this.downpayment) * interest * ratio;
      this.totalAmount = this.loanAmount * term;
      this.emi = {
        emi: this.loanAmount.toFixed(0),
        total: this.totalAmount.toFixed(0),
      };
    },
  },
  components: {
    ProductList,
    Loading,
  },
  computed: {
    ...mapGetters(["allFilters", "allProducts","allProduct"]),
  },
  async created() {
    this.loadCalculator();
    await this.getFrotendProducts({
      limit: this.limit,
      page: this.page,
      productType: this.productType,
    });
    this.products = this.allProducts.results;

    let filter = {};
    await this.getFilters(filter);
    this.filters = this.allFilters.results;
    this.categories = this.filters.category.items;

    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";

.v-btn {
  min-height: 5 0px;
  width: 200px;
  color: #0878d4 !important;
  background: #fff !important;
  border: 1px solid #0878d4;
  box-shadow: none !important;
}
.filterationBox {
  border: 1px solid #efefef;
  padding: 20px 20px 10px;
  margin-bottom: 20px;
  a {
    color: #000;
  }
  header {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
    font-weight: 500;
    span {
      font-size: 14px;
      .v-icon {
        font-size: 20px;
      }
    }
  }
  .toogleBox {
    display: none;
  }
  .filterBox {
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 10px;
    li {
      font-size: 14px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      div {
        margin-top: -15px;
      }
    }
  }
}
.v-input {
  margin-top: 0 !important;
}
.secondIcon {
  margin-left: -15px;
}
.detailBox {
  border: 2px solid #f4f4f4;
  padding: 20px;
  margin-top: 20px;
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .detailBoxList {
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    li {
      width: 33.33%;
      display: flex;
      padding: 15px 0;
      flex-flow: column;
      .title {
        font-size: 14px !important;
        font-weight: 400;
        color: #8c8c8c;
        .v-icon {
          font-size: 18px;
          color: #0878d4;
        }
      }
      strong {
        font-size: 16px;
      }
    }
  }
  .detailKeys {
    .titleNormal {
      font-size: 16px !important;
    }
    li {
      width: 50%;
    }
  }
  .detailSpecifications {
    .titleNormal {
      font-size: 16px !important;
    }
    li {
      width: 100%;
      padding: 5px 0;
      .title {
        font-size: 16px !important;
        display: flex;
        align-items: center;
        .v-icon {
          margin-right: 10px;
        }
      }
    }
  }
}

h2 {
  font-size: 30px;
}
.priceBox {
  font-size: 25px;
  margin-top: 15px;
  margin-bottom: 15px;
  display: block;
}
header {
  .title {
    font-size: 20px;
    font-weight: 700;
  }
}
.primaryBtn {
  background: #0878d4 !important;
  color: #fff !important;
  min-height: 60px;
  width: 280px;
  text-transform: capitalize;
  .v-icon {
    font-size: 18px;
    margin-left: 10px;
  }
}
.v-btn {
  .v-icon {
    font-size: 18px;
    margin-left: 10px;
  }
}

.navList {
  padding-left: 0;
  list-style: none;
  display: flex;
  font-size: 14px;
  li {
    .v-icon {
      font-size: 18px;
      margin-left: 5px;
    }
    // margin-right: 15px;
  }
}
.productImages {
  img {
    width: 100%;
  }
}
.faqPill {
  header {
    width: 100%;
    margin-bottom: 10px;
    ul {
      width: 100%;
      display: flex;
      padding-left: 0;
      list-style: none;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #efefef;
      padding: 10px 0;
    }
  }
  header {
    &:last-of-type {
      ul {
        border-bottom: 0;
      }
    }
  }
}
.emiDetail {
  flex-flow: column;
  padding: 15px;
  ul {
    padding-left: 0;
    list-style: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    color: #8c8c8c;
  }
}

.flexBox {
  display: flex;
  width: 100%;
  ul {
    display: flex;
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    li {
      width: 56px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #9b9a9a;
      font-size: 18px;
      cursor: pointer;
      &.active {
        background: rgb(226, 231, 226);
      }
    }
  }
}
.v-text-field {
  border-radius: 0;
  width: 100%;
}
.totalSection {
  list-style: none;
  padding-left: 0;
  li {
    font-size: 18px;
    font-weight: 600;
    line-height: 35px;
    display: flex;
    justify-content: flex-start;
    span {
      display: block;
      margin-right: 5px;
    }
  }
}
.no-border {
  &-left {
    border-left: 0 !important;
  }
  &-right {
    border-right: 0 !important;
  }
}
.flex {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-flow: column;
}
.widthFull {
  padding: 0 15px;
  width: 100%;
}
</style>