<template>
  <section class="box">
    <div class="productCardTop">
      <router-link :to="`/product/${item._id}`">
        <img :src="item.medias.mediaLink" alt="" />
      </router-link>
    </div>
    <div class="productCardBody">
      <router-link :to="`/product/${item._id}`">
        <h4>{{ item.productName }}</h4>
      </router-link>
      <p v-if="item.discountPercentage != 0">
        Rs. {{ item.price - item.discountPercentage * 100 }}
        <strike>Rs. {{ item.price }}</strike>
      </p>
      <p v-if="item.discountPercentage == 0">Rs. {{ item.price }}</p>
    </div>
  </section>
</template>

<script>
export default {
  name: "ProductList",
  props: ["item"],
};
</script>

<style lang="scss" scoped>
.box {
  border: 2px solid #ededed;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  width: 100%;
  // margin-bottom: 20px;
}
.borderBox {
  border-bottom: 1px solid #ededed;
  display: block;
}
img {
  width: 100%;
  object-fit: contain;
  height: 100px;
}
a {
  text-decoration: none;
  color: #000;
}
.productCardBody {
  padding: 15px;
  margin-top: 10px;
  h2 {
    margin-bottom: 10px;
    font-size: 18px;
  }
}
</style>